@import '_utils';

.table-filters-container {
    border: 1px solid $white;
    margin: auto;
    width: 98%;

    & .collapse,
    & .collapsing {
        width: 100%;
    }

    & .show {

        & .card-body {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 1.25rem;

            & form {
                width: 100%;
            }

            & form .form-row{
                height: 36px;
            }
        }
    }

    .card {
        display: flex;
        flex-flow: row;
        background-color: #DCE4EE ;
        overflow: visible;
        
        @media only screen and (max-width: $mid-laptop) {
            flex-flow: unset;
            flex-direction: column;
        }

        & .col .form-control {
            height: 36px;
        }

        .card-body .col input,
        .card-body .picky__input{
            font-size: 16px;

            @media only screen and (max-width: $mid-laptop) {
                & {
                    font-size: 14px;
                }
            }
        }

        @media only screen and (max-width: $mid-laptop) {
            .btn-with-icon {
                font-size: 14px;
            }
        }

        

        .filters-label-container {
            margin-left: 20px;

            .actions-label {
                margin-left: 20px;
                margin-top: 2px;
            }
    
            .action-buttons-container {
                margin-top: 5px;
    
                button {
                    margin-left: 30px;
                    margin-right: 30px;
                }
            }
        }

        .filters-fields {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            @media only screen and (max-width: $mid-laptop) {
                & {
                    min-height: 43px;
                }

                & .btn-with-icon {
                    max-width: 8vw;
                }
            }

            & input,
            & .picky__placeholder {
                display: block;
                @include text-overflow();
            }

            & .picky__placeholder {
                width: 86%;
            }

            & .btn-with-icon {
                margin-right: 30px;
                @media only screen and (max-width: $mid-laptop){
                    & {
                        margin-right: unset;
                    }
                }
            }

            .col {
                height: 100%;

                #picky {
                    &:focus {
                        outline: 0; 
                    }
                    
                    #picky__button__button {
                        border: 1px solid #ced4da;
                        height: 100%;
                        border-radius: 0.2rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

                        &:focus {
                            // outline: 1px solid $primary; 
                            background-color: $white;
                            border-color: #a9ccf6;
                            outline: 0;
                            box-shadow: 0 0 0 0.2rem rgba(53, 135, 235, 0.25);
                        }

                        .picky__placeholder {
                            color: $third;
                            // outline: 0;
                        }
                    }
                }
            }
        }
    }
}


.picky {
    height: 36px;
    box-sizing: border-box;
}

.picky .option {
    display: flex;
    align-items: center;

    @media only screen and (max-width: $small-laptop) {
        & {
            font-size: 15px;
        }
    }
}

.picky__dropdown {
    min-width: 210px;
}
