@import '_utils';

.table-actions-container {
    border: 1px solid $white;

    .action-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-left: 2rem;

        .add-user-action {
            margin: 0px auto 0px auto;
            padding-top: 4px;
            border-right: 2px solid $white;
            width: 80%;

            .add-user-title {
                color: $primary;
                font-weight: bold;
            }

            .form-row {
                justify-content: space-evenly;
                height: 36px;

                .btn-with-icon {
                    margin-right: 2rem;
                    margin-left: 2rem;
                }

                .col {
                    .add-user-form-group {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 1rem;
                    }

                    span {
                        width: 6rem;
                        display: inline-flex;
                        align-items: center;
                        margin-right: 0.5rem;
                    }
                }
            }
        }

        .other-actions {
            display: flex;
            flex-direction: row;
            margin: auto;

            .other-title {
                color: $primary;
                font-weight: bold;
                position: absolute;
                top: 2px;
                margin-left: 15px;
            }

            .btn-with-icon {
                margin-right: 1rem;
                margin-left: 1rem;
            }
        }
    }
}