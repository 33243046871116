@import "_utils";

.popover {
    .popover-body {
        height: 100%;
        width: 100%;

        .container {
            height: 100%;
            width: 100%;

            .close-icon {
                float: right;
                cursor: pointer;
            }

            .event-details-rows-container {
                padding: 5px 5px 5px 5px;

                .row {
                    font-size: 1.8vh;
                    margin-bottom: 5px;

                    .event-data-item-key {
                        color: $third;
                    }

                    .event-data-item-value {
                        color: black;
                    }
                }
            }
            .map-container {
                border: 1px solid black;
                padding: 5px 5px 5px 5px;
                height: 100%;
                width: 100%;
                .no-location {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    margin-top: 25%;
                }
            }

            .media-container {
               
                .media-counter{
                    position: absolute;
                    font-weight: bolder;
                    bottom: 0;
                    right: 0;
                }
               
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                .video-container {
                    width: fit-content;
                    position: relative;
                    margin: auto;
                    height: 100%;
                video{
                   width: unset;
                } 
                }
            }
        }
    }
}

.img-container-btn {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
}

.swiper-container {
    height: 100%;
}

