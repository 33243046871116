@import '_utils';

.table-actions-container {
    border: 1px solid $white;

    .card {
        display: flex;
        flex-flow: row;
        background-color: #DCE4EE ;

        .actions-label {
            margin-left: 20px;
            margin-top: 2px;
        }

        .action-buttons-container {
            margin-top: 5px;

            button {
                margin-left: 30px;
                margin-right: 30px;
            }
        }
    }
}