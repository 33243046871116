@import '_utils';

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: $white;
    box-shadow: 0px 3px 10px $third-light;
  }
