@import '_utils';

.top-nav {
    .logos-bar {
        height: 60px;
        padding-left: 90px;
        padding-right: 90px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .img-container {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            vertical-align: middle;

            img {
                margin: auto;
                max-height: 50px;
            }

            span {
                margin: auto;
                text-align: left;
                font: italic normal 600 32px/38px Aglet Sans;
                letter-spacing: 0px;
                color: #000000;
            }
        }
    }

    .navbar {
        height: 3rem;
        padding: 0;
        box-shadow: 0px 3px 10px $third-light;

        .navbar-collapse {
            height:100%;

            .navbar-nav {
                padding-left: 30%;
                width: 100%;
                height: 100%;
                justify-content: space-between;

                .nav-pages-link-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 50%;

                    .nav-item {
                        width: 155px;
                        display: flex;
                        justify-content: space-around;
                        padding-right: 10px;

                        &.clicked {
                           border-bottom: 3px solid $white; 
                        }

                        .icon-with-label {
                            height: 100%;
                        }
                    }
                }

                .nav-user-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    margin: 0px 30px 0px 30px;

                    .nav-item {
                        width: 155px;
                        display: flex;
                        justify-content: space-around;
                        padding-right: 10px;
                        margin: auto;

                        .logout-span {
                            color: $secondary;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}