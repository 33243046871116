//variables
// *** //

//colors
$primary: #3587EB;
$primary-light: #58A3FF;
$secondary: #92D050;
$secondary-light: #A4E85B;
$secondary-extra-light: #F2FBEA;
$third: #576271;
$third-light: #6F7D91;
$third-extra-light: #F5F5F5 ;
$white: white;
$black: black;
$blackish-text: #666666;
// screen sizes
$small-laptop: 1200px;
$mid-laptop: 1470px;

//mixins
@mixin btn($color, $textColor, $hoverColor) {
    background-color: $color;
    color: $textColor;

    &:hover {
        background-color: $hoverColor;
        color: $textColor;
    }

    &:disabled {
        opacity: 50%;
    }
}

@mixin shadow {
    box-shadow: 0px 3px 6px #0000005C;
}

@mixin text-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@mixin customTooltip($background: $white, $borderColor: $primary, $textColor: $black) {
    background: $background;
    border: 1px solid $borderColor;
    color: $textColor;
}


//buttons
.btn-primary {
    @include btn($primary, $white, $primary-light);
}

.btn-secondary {
    @include btn($secondary, $white, $secondary-light);
}

.btn-third {
    @include btn($third, $white, $third-light);
}

.btn-white {
    @include btn($third, $white, $third-light);
}

.btn-gray {
    @include btn($third-extra-light, $blackish-text, $third-extra-light);
}


//general

body, html {
    height: 100%;
    video::-webkit-media-controls-panel  {
       opacity: 1 !important;
  }
    &.bg {
        background-image: url("../assets/middleware-background.png");

        /* Full height */
        height: 100%;
      
        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .clickable {
        cursor: pointer; 
    }
    
    .clickable-with-shadow {
    
        &:hover {
            @include shadow;
            color: $primary;
            cursor: pointer; 
        }
    }
    
    .selected {
        background-color: $secondary-extra-light;
    
        &:hover {
            background-color: $secondary-extra-light !important;
        }
    }
    
    .spinner-border {
        margin: auto;
    }
    

}

body {
    @media only screen and (max-width: $small-laptop) {
        zoom: 79%;
    }
}

a {
    cursor: pointer;
}

input {
    min-width: 0;
}

button:focus {
    outline: none;
}