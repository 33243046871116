@import '_utils';

    .btn-with-icon {

        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        box-shadow: 0px 3px 6px $third;
        border-radius: 3px;
        color: $white;
        border:none;
        cursor: pointer;

        &:active,
        &:focus {
            outline: none;
        }

        .btn-with-item-content {
            height: 100%;
            display: flex;
            justify-content: center;
            width:100%;
            padding-right: 10%;

            .icon-container {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left:5px;
                margin-right: 5px;
            }

            .text-container {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
            }
        }
    
        &.secondary {
            background: $secondary 0% 0% no-repeat padding-box;
        }

        &.primary {
            background: $primary 0% 0% no-repeat padding-box;
        }

        &.third {
            background: $third 0% 0% no-repeat padding-box;
        }

        &.white {
            color: $third;
            background: $white;
            box-shadow: none;
        }

        &.disabled {
            color: black;
            opacity: 0.5;
            cursor: default;
        }
}