@import '_utils';

.modal-content {
    background-color: $third-extra-light;

    & .custom-file-upload {
        margin-top: 20px;
    }

    .driver-details-container {
        background-color: $third-extra-light;

        .card {
            width: 90%;
            margin: auto;
            border: none;
            background-color: $third-extra-light;

            .card-body {
                padding-left: 1rem;
                padding-right: 1rem;

                .row {
                    height: 55px;
                    background-color: $white;
                    border: 1px solid $white;
                    border-radius: 3px 3px 0px 0px;
                
                    .col {
                        display: flex;
                        flex-direction: row;

                        span {
                            margin-top: auto;
                            margin-bottom: auto;
                        }

                        .form-control {
                            margin-left: 7px;
                            width: 255px;
                            margin-top: auto;
                            margin-bottom: auto;
                        }

                        .input-item-span {
                            margin-top: auto;
                            margin-bottom: auto;
                            padding-left: 20px;
                            font-weight: bold;
                        }
                    }

                    svg {
                        margin: auto;
                    }
                }
            }
        }
    }
    .modal-body-container {

        .card {
            margin: auto;
            width: 90%;
            height: 480px;
            border: 2px solid $white;
            background-color: $third-extra-light;

            .img-container {
                margin: auto;
                max-width: 70vh;
                max-height: 40vh;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    box-shadow: 0px 3px 6px #00000029;
                    border: 1px solid #707070;
                }
            }

            .card-body{
                flex-direction: column;
                justify-content: space-around;
                display: flex;

                .body-content {
                    margin: auto;
                    width: 250px;
                    text-align: center;

                    .btn-with-icon {
                        margin: 20px auto auto auto;
                    }

                    .description-text {
                        margin-top: 20px;
                    }
                }
            }
        }

    }
}