@import '_utils';

.toast {
    .toast-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .toast-body {

        ul.owners-options-list {
            position: absolute;
            background-color: $white;
            border: 1px solid rgba(0, 0, 0, 0.1);
            box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            padding: 5px;

            li.owner-option {
                margin-left: 10px;
                margin-right: 10px;
                list-style-type: none;
                color: $third;

                &:hover {
                    cursor: pointer;
                    background-color: $secondary;
                }
            }
        }
    }
}