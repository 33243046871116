@import '_utils';

.page-title-container {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 1%;
    padding-right: 1%;

    .page-title {
        display: flex;
        justify-content: space-between;

        .page-title-icon {
            display: flex;
            margin: auto;
        }

        .page-title-text {
            margin: auto;
            font-weight: 800;
        
            &.secondary {
                color: $secondary;
            }

            &.primary {
                color: $primary;
            }

            &.third {
                color: $third;
            }
        }
    }

    .export {
        margin-top: auto;
        margin-bottom: auto;
    }
}