@import '_utils';

.login-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 480px;
    height: 420px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #58A3FF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 40px #003C85;

    .login-form {
        width: 300px;
        height: 280px;
        margin: auto;

        .login-form-title {
            color: $white;
            margin-bottom: 25px;
            text-align: center;
            font-size: 50px;
            text-shadow: 0px 1px 1px #00000029;
            font-weight: 10;
        }

        .form-login-error {
            color: #FFA217;
        }

        button {
            width: 100%;
        }
    }

    .login-logos {
        width: 100%;
        padding: 0 30px 15px 30px;

        .img-container {

            img {
                float: right;
                width: 45%;
            }
            span {
                margin: auto;
                text-align: left;
                font: italic normal 600 32px/38px Aglet Sans;
                letter-spacing: 0px;
                color: #000000;
            }
        }
    }
}