@import '_utils';

#overflow-tooltip,
.custom-tooltip {

    &[x-placement="top"]{
        .arrow:before {
            border-top-color: $primary;
        }
    }

    &[x-placement="right"]{
        .arrow:before {
            border-right-color: $primary;
        }
    }

    &[x-placement="bottom"]{
        .arrow:before {
            border-bottom-color: $primary;
        }
    }

    &[x-placement="bottom"]{
        .arrow:before {
            border-bottom-color: $primary;
        }
    }
}

.tooltip-inner {
    @include customTooltip();
    min-width: 100%;
}

.custom-tooltip .col {
    padding: 0 5px;
}

