.icon-with-label {
    display: flex;
    flex-direction: row;

    .icon {
        display: flex;
        margin-right: 5px;

        svg {
            align-self: center;
        }
    }

    .label {
        align-self: center;
    }
}

