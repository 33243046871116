@import '_utils';


.date-range-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & .custom-tooltip {
        width: 49%;
    }

    .react-datepicker-wrapper {
        width: 100%;

        input {
            font-size: 16px;
            height: 34px;
            border-radius: 3px;
        }
    }
}

.react-datepicker-popper {
    background-color: $white;
    @include shadow;

    .react-datepicker {

        border: none;

        .react-datepicker__triangle {
            border-bottom-color: $white;

            &::before {
                border-bottom-color: $third-light;
            }
        }

        .react-datepicker__navigation {
            border: solid $third;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 2px;

            &:focus {
                outline:0;
            }

            &.react-datepicker__navigation--previous {
                transform: rotate(135deg) !important;
                -webkit-transform: rotate(135deg) !important;
            }

            &.react-datepicker__navigation--next {
                transform: rotate(-45deg) !important;
                -webkit-transform: rotate(-45deg) !important;
            }
        }

        .react-datepicker__month-container{

            .react-datepicker__header {
                background-color: transparent;
                border: none;

                .react-datepicker__current-month {
                    color: $primary;
                }
            }

            .react-datepicker__month {

                .react-datepicker__day {
                    margin: auto;
                    border: 1px #E8E9EC solid;
                
                    &:hover {
                        background-color: $secondary;
                        border-radius: 0;
                    }

                    &.react-datepicker__day--keyboard-selected {
                        background-color: $secondary;
                        border-radius: 0;
                    }
                    
                    &.react-datepicker__day--selected {
                        background-color: $secondary;
                        border-radius: 0;
                    }
                }
            }
        }
    }
}


.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 92px;
}

.react-datepicker__header.react-datepicker__header {
    background-color: $white;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    color: $primary;
}
