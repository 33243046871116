@import '_utils';

.pagination {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 40px;

    .pagination-item {
        margin: auto 5px auto 5px;

        &.clickable {
            cursor: pointer;

            &:hover {
                color: $primary;
            }
        }

        .pagination-input {
            width: 40px;
            text-align: center;
            //remove arrows from input type number
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                display: none;
                -webkit-appearance: none;
                margin: 0; 
            }

            &[type=number] {
                -moz-appearance:textfield; /* Firefox */
            }
        }
    }
}