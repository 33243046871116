@import '_utils';

.table-with-extensions-container {
    margin: auto;
    width: 98%;

    .table-filters-container {
        border: 1px solid $white;
    
        .card {
            display: flex;
            flex-flow: row;
            background-color: #DCE4EE ;
    
            .filters-label-container {
                margin-left: 20px;
    
                .actions-label {
                    margin-left: 20px;
                    margin-top: 2px;
                }
        
                .action-buttons-container {
                    margin-top: 5px;
        
                    button {
                        margin-left: 30px;
                        margin-right: 30px;
                    }
                }
            }
    
            .filters-fields {
                width: 100%;
            }
        }
    }

    .table-responsive {

        thead {
            background-color: $third-extra-light;
        }

        tbody {

            tr {

                td {

                    &.owner {
                        display: flex;
                        justify-content: space-between;

                        .remove-owner-icon {
                            color: red;
                        }
                    }

                    &.add-owner {
                        color: $primary;
                    }

                }
            }
        }

    }
    
    option.dropdown-filter-option {
        
        &:hover {
            background-color: red !important;
        }
    }

}